<style lang="scss" scoped>
</style>
<template>

    <div>
        <editor
            :tinymceScriptSrc="options.base_url+'tinymce.min.js'"
            :id="id"
            :api-key="apiKey"
            :init="options"
            :disabled="disabled"
            v-model="content">
        </editor>
    </div>

</template>

<script type="text/ecmascript-6">
import Editor from '@tinymce/tinymce-vue'
import {up2QiNiu} from '@/common/js/up2QinNiu';

export default {
    // name: 'tinymce-editor',
    data() {
        return {
            apiKey: 's71gwow5j4mqip34wrexxyzlae4fi40p30z1sm1la7pze6x0',
            options: {
                base_url: `${process.env_project.PUBLISH_PATH}/tinymce/`,
                selector: `#${this.id}`,
                language: 'zh_CN',
                menubar: false,
                width: this.width,
                height: this.height,
                body_class: 'panel-body',
                object_resizing: true,
                statusbar: false,
                font_formats: "微软雅黑=微软雅黑,Microsoft YaHei;宋体=宋体,SimSun;黑体=黑体, SimHei;隶书=隶书, SimLi;楷体=楷体,楷体_GB2312, SimKai;andale mono=andale mono;arial=arial, helvetica,sans-serif;arial black=arial black,avant garde;comic sans ms=comic sans ms;impact=impact,chicago;Arial=Arial;Verdana=Verdana;Georgia=Georgia;Times New Roman=Times New Roman;Trebuchet MS=Trebuchet MS;Courier New=Courier New;Impact=Impact;Comic Sans MS=Comic Sans MS;Calibri=Calibri",
                fontsizeFormats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 24pt 36pt 48pt',
                images_upload_handler: async (blobInfo, success) => {
                    let file = blobInfo.blob();
                    let upImgKey = '/product/' + (+new Date()) + '/';
                    let extend = ".jpg";
                    if (blobInfo.blob().name) {
                        extend = file.name.split('.')[1];
                    }
                    let imgName = (+new Date()) + '.' + extend;
                    up2QiNiu(this, (upImgKey + imgName), file).then((res) => {
                        success(res)
                    })
                },
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor textcolor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table contextmenu paste code help wordcount'
                ],
                toolbar: 'image | undo redo |  formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | code table | bullist numlist outdent indent | removeformat | help',
                default_link_target: '_blank',
                content_css: [
                    '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
                    './css/codepen.min.css'],
                setup(editor) {
                    editor.ui.registry.addButton('h2', {
                        title: '小标题', // tooltip text seen on mouseover
                        text: '小标题',
                        onclick() {
                            editor.execCommand('mceToggleFormat', false, 'h2');
                        },
                        onPostRender() {
                            const btn = this;
                            editor.on('init', () => {
                                editor.formatter.formatChanged('h2', state => {
                                    btn.active(state);
                                });
                            });
                        }
                    });
                    editor.ui.registry.addButton('p', {
                        title: '正文',
                        text: '正文',
                        onclick() {
                            editor.execCommand('mceToggleFormat', false, 'p');
                        },
                        onPostRender() {
                            const btn = this;
                            editor.on('init', () => {
                                editor.formatter.formatChanged('p', state => {
                                    btn.active(state);
                                });
                            });
                        }
                    });
                    editor.on('joinimageuploadstart', () => {
                        this.$message({duration: 0, message: "正在上传图片..."});
                    });
                    editor.on('joinimageuploadend', () => {
                        this.$message.closeAll();
                    });
                    editor.on('joinimageuploaderror', (e) => {
                        this.$message({duration: 0, message: e.errMsg});
                        setTimeout(() => {
                            this.$message.closeAll();
                        }, 3000);
                    });
                }
            }
        }
    },
    props: {
        id: {
            //如果有多个编辑器,请加id
            type: String,
            default: 'tinymceEditor'
        },
        value: {
            type: String,
            default: ''
        },
        menubar: {
            default: ''
        },
        height: {
            type: Number,
            required: false,
            default: 360
        },
        width: {
            type: Number,
            required: false,
            default: 1000
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components: {
        'editor': Editor
    },
    //计算属性
    computed: {
        content: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
                this.$emit('update:editor', val)
            }
        }
    },
    //主件被加载完成
    mounted() {
        console.log(23232,process.env_project.PUBLISH_PATH);
    },
    //定义事件方法
    methods: {},
    //监听模版变量
    watch: {}

}
</script>
